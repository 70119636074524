import React from "react";
import classes from "./Input.module.css";

const Input = props => {
  return (
    <div>
      <input
        onChange={props.change}
        className={classes.Input}
        type={props.type}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
      />
    </div>
  );
};

export default Input;
