import Input from './components/Input';
import Header from './components/Header';
import Player from './components/Player';
import Teamblock from './components/Teamblock';
import { Component } from 'react';
import classes from './App.module.css';
import axios from 'axios';
import Spinner from './components/Spinner';

class App extends Component {
    state = {
        name: '',
        team: 'ČERVENÝ',
        red: [],
        black: [],
        blue: [],
        message: '',
    };

    componentDidMount() {
        this.loadHandler();
    }

    loadHandler = () => {
        axios
            .get('https://floorball-b3237-default-rtdb.firebaseio.com/.json')
            .then((response) => {
                let red = [];
                let black = [];
                let blue = [];
                let teams = { red, black, blue };

                if (!response.data) {
                    return;
                } else {
                    Object.keys(response.data).map((varname) => {
                        return Object.keys(response.data[varname]).map(
                            (key) => {
                                return teams[varname].push({
                                    name: response.data[varname][key].name,
                                    id: [key],
                                });
                            }
                        );
                    });

                    this.setState({ red: red, black: black, blue: blue });
                }
            })

            .catch((error) => {
                console.log(error);
            });
    };

    postFunction = (name, team) => {
        let data = { name: name };
        axios
            .post(
                'https://floorball-b3237-default-rtdb.firebaseio.com/' +
                    team +
                    '.json',
                data
            )
            .then(this.loadHandler);
    };

    nameHandler = (event) => {
        let newState = { ...this.state };
        newState.name = event.target.value.toUpperCase();
        this.setState(newState);
    };
    teamHandler = (event) => {
        let newState = { ...this.state };
        newState.team = event.target.value;
        this.setState(newState);
    };

    submitHandler = (e) => {
        e.preventDefault();
        let message = '';

        if (this.state.name.trim() === '') {
            message = 'Musíš vyplniť meno!';
            this.setState({ message: message });
            return;
        }

        let addedName = this.state.name.toUpperCase();
        let addedTeam = this.state.team;
        let resetName = '';
        let mergedArray = this.state.black.concat(this.state.red);
        let check = mergedArray.some((item) => {
            return item.name === addedName;
        });

        if (check) {
            message = 'Toto meno už je na súpiske použité. Musíš použiť iné.';
            this.setState({ message: message });
            return;
        }
        message = '';
        this.setState({ message: message, name: resetName });
        let varTeam = '';
        if (addedTeam === 'ČERVENÝ') {
            varTeam = 'red';
        }
        if (addedTeam === 'ČIERNY') {
            varTeam = 'black';
        }
        if (addedTeam === 'MODRÝ') {
            varTeam = 'blue';
        }

        if (this.state[varTeam].length < 10) {
            this.postFunction(addedName, varTeam);
        } else {
            message = 'Tento tím už má plný počet hráčov';
            this.setState({ message: message });
        }
    };

    removeHandler = (id, team) => {
        if (window.confirm('Naozaj chceš odstrániť tohoto hráča?')) {
            this.afterRemoveHandler(id, team);
        }
        return;
    };

    afterRemoveHandler = (id, team) => {
        let newTeam = this.state[team].filter((item) => item.id !== id);
        axios
            .delete(
                'https://floorball-b3237-default-rtdb.firebaseio.com/' +
                    team +
                    '/' +
                    id +
                    '.json'
            )
            .then(this.loadHandler);
        this.setState({ [team]: newTeam });
    };

    beforeRender = (team) => {
        return this.state[team].map((item, i) => {
            return (
                <Player
                    key={item.id}
                    index={i}
                    click={() => this.removeHandler(item.id, team)}
                >
                    {item.name}
                </Player>
            );
        });
    };

    render() {
        let redList = <Spinner />;
        let blackList = <Spinner />;
        let blueList = <Spinner />;

        if (this.state.red) {
            redList = this.beforeRender('red');
        }

        if (this.state.black) {
            blackList = this.beforeRender('black');
        }

        if (this.state.blue) {
            blueList = this.beforeRender('blue');
        }

        let style = '';
        switch (this.state.team) {
            case 'ČERVENÝ':
                style = 'red';
                break;
            case 'ČIERNY':
                style = 'black';
                break;
            case 'MODRÝ':
                style = 'blue';
                break;
            default:
                return;
        }

        return (
            <div className={classes.App}>
                <Header />
                <form>
                    <div className={classes.Selection}>
                        <select
                            value={this.state.team}
                            onChange={this.teamHandler}
                            className={[classes.Selection, classes[style]].join(
                                ' '
                            )}
                        >
                            <option className={classes.Red}>ČERVENÝ</option>
                            <option className={classes.Black}>ČIERNY</option>
                            <option className={classes.Blue}>MODRÝ</option>
                        </select>
                        <Input
                            change={this.nameHandler}
                            type="text"
                            name="player"
                            value={this.state.name}
                            placeholder="MENO HRÁČA"
                        />
                        <button
                            onClick={(e) => this.submitHandler(e)}
                            className={classes.Add}
                        >
                            ZAPÍSAŤ
                        </button>
                    </div>
                </form>

                {this.state.message ? (
                    <p className={classes.Warn}>{this.state.message}</p>
                ) : null}

                {!!this.state.red.length && (
                    <Teamblock typ="Red">ČERVENÝ TÍM</Teamblock>
                )}
                {!!this.state.red.length && (
                    <div className={classes.Block}>{redList}</div>
                )}
                {!!this.state.black.length && (
                    <Teamblock typ="Black">ČIERNY TÍM</Teamblock>
                )}
                {!!this.state.black.length && (
                    <div className={classes.Block}>{blackList}</div>
                )}
                {!!this.state.blue.length && (
                    <Teamblock typ="Blue">MODRÝ TÍM</Teamblock>
                )}
                {!!this.state.blue.length && (
                    <div className={classes.Block}>{blueList}</div>
                )}
            </div>
        );
    }
}

export default App;
