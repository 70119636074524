import React from "react";
import classes from "./Player.module.css";

const Player = props => {
  return (
    <div className={classes.Player}>
      <div className={classes.Number}>{props.index + 1}</div> {props.children}
      <button onClick={props.click} className={classes.Button}>
        ODSTRÁNIŤ
      </button>
    </div>
  );
};

export default Player;
